body {
  font-family: $default-font-family;
  background-color: #f3f3f3;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1rem;
}

a {
  color: $color-tertiary;
  text-decoration: underline;
  &:hover {
    color: $dark-gray;
  }
}

hr {
  border-color: #ccc;
}

ol {
  li {
    padding-left: 10px;
  }

  li:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.video-name {
  text-decoration: underline;
  font-weight: bold;
}

.wrapper {
  display: flex;

  @include respond-to(screen) {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
  }
}

.page-content {
  padding: 40px 0;

  @include on-mobile {
    padding-top: 60px;
  }
}

.profile-image {
  &.image-tiny {
    width: 30px;
    height: 30px;
  }

  &.image-xs {
    width: 65px;
    height: 65px;
  }

  &.image-sm {
    width: 85px;
    height: 85px;
  }

  &.image-md {
    width: 100px;
    height: 100px;
  }

  &.image-lg {
    width: 125px;
    height: 125px;
  }

  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
  }
}

.contract-padding {
  padding-bottom: 55px;
}

.zero-font {
  font-size: 0;
}

.success-icon {
  font-size: 1.5em;
  vertical-align: middle;
  margin-top: -0.3em;
  margin-right: 0.2em;
}

// Add Organization Modal

.add-org {
  .uploadOverlay {
    width: 48%;
    min-width: 250px;
    margin-top: 0.2em;
  }

  #upload-logo {
    img {
      padding: 0.5em 0.5em 1em 0.5em;
      min-height: 100px;
    }
  }

  .modal {
    position: relative;
    background: none;

    .modal-dialog {
      width: 100%;
      box-shadow: none;
      border: 1px solid $border_color;
      margin: 1.5em 0 2em;
    }

    canvas {
      max-width: 275px;
      height: auto !important;

      @include respond-to(tablet) {
        max-width: 480px;
      }
    }
  }
}

.text-white {
  color: #fff;
}
